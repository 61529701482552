import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  ProductDetailsPage,
  CategoryPage,
  UploadPage,
  PaymentSuccessPage,
  PaymentPortalPage,
  CreditCardsPage,
  TaxExemptionsPage,
  AddressesPage,
  SettingsPage,
  ProofApprovalsPage,
  TrackingPage,
  OrderHistoryPage,
  InformationPage,
  HelpCenterPage,
  SignUpPage,
  SignInPage,
  ResetPasswordPage,
  ResendConfirmationPage,
  CartPage,
  CheckoutPage,
  ProductListingPage,
  SiteMap,
  RushCritical,
  FranchiseSolutions,
  Insights,
  BoxDesign,
  DesignServices,
  CloudEnterpriseServices,
  HomePage,
  Success,
  ContactUsPage,
  HoursAndLocation,
  CareersPage,
  OurStoryPage,
  OrderDetailsPage,
  ContactSales,
  PaperShortagePage,
  EditPassword,
  FourOhFour,
} from './DynamicPages.imports-loadable';

import UserContext from 'contexts/UserContextContainer/UserContext';
import AppContext from 'contexts/AppContext/AppContext';
import { NavContainer } from 'styleguide/components';
import { CmsCategoryPage, CmsProductPage, CmsServicePage } from '../../contexts/AppContext/types';
import { isProductPage } from 'libs/utils/pageUtils';

export const signInRedirectPath = '/';
export const homePagePath = '/';
export const signInPath = '/sign-in';
export const resetPasswordPath = '/password-reset';
export const editPasswordPath = '/edit-password';
export const signUpPath = '/sign-up';
export const resendConfirmationPath = '/resend-confirmation';
export const helpCenterPath = '/help-center';
export const paperShortagePath = '/paper-shortage';

export const products = '/products';
export const contactUsPath = '/contact-us';
export const hoursAndLocationPath = '/hours-and-location';
export const cartPath = '/cart';
export const checkoutPath = '/checkout';
export const privacyPath = '/privacy-policy';
export const termsAndConditionsPath = '/terms-and-conditions';
export const convid19faqsPath = '/covid-19-faqs';
export const careersPath = '/careers';
export const ourStoryPath = '/our-story';
export const orderDetailsPath = '/order-details';
export const siteMapPath = '/site-map';
export const uploadPath = '/upload';

export const socialPath = {
  instagram: 'https://www.instagram.com/PrintivityUSA',
  facebook: 'https://www.facebook.com/PrintivityUSA',
  twitter: 'https://www.twitter.com/Printivity',
};

// Keep this in case we need to reenable selective blue buttons
export const blueButtonPaths: string[] = [
  '/landing/booklets',
  '/landing/catalogs',
  '/landing/magazines',
  '/landing/saddle-stitched-booklets',
  '/booklets/saddle-stitched-booklets',
  '/books/perfect-bound-books',
  '/books/pur-perfect-bound-books',
  '/booklets/spiral-bound-booklets',
  '/booklets/wire-o-booklets',
  '/landing/color-copies',
  '/copies/color-copies',
  '/landing/mini-posters',
  '/signage/mini-posters',
  '/marketing/postcards',
  '/marketing/flyers',
  '/marketing/brochures',
  '/boxes/shipping-boxes',
];

export const verticalPaths: string[] = [
  '/charity-and-non-profit',
  '/education-and-schools',
  '/events',
  '/fundraisers',
  '/funerals-and-memorials',
  '/hospitality-and-tourism',
  '/pageants',
  '/restaurants',
  '/retail',
  '/small-business',
  '/sports-printing',
  '/training-and-presentations',
];

export const blogPath = '/insights';

export const servicePath = {
  rushCriticalPath: '/rush-critical',
  franchiseSolutionsPath: '/franchise-solutions',
  boxDesignPath: '/box-design',
  insightsPath: '/landing/insights',
  designServices: '/design-services',
  cloudEnterpriseServices: '/cloud-enterprise-services',
  cloudEnterpriseServicesContactSales: '/cloud-enterprise-services/contact-sales',
};

export const accountPath = {
  account: '/account',
  orders: '/account',
  addresses: '/account/addresses',
  taxExemptions: '/account/tax_exemptions',
  creditCards: '/account/credit_cards',
  settings: '/account/settings',
};

const Routes = ({ url }) => {
  const appContext = React.useContext(AppContext);
  const userContext = React.useContext(UserContext);
  const cmsProductPages: CmsProductPage[] = appContext.store.cmsPages.filter(
    (elem: CmsProductPage | CmsCategoryPage | CmsServicePage) => elem.type === 'Comfy::Cms::Page::Product',
  ) as CmsProductPage[];
  const cmsCategoryPages: CmsCategoryPage[] = appContext.store.cmsPages.filter(
    (elem: CmsProductPage | CmsCategoryPage | CmsServicePage) =>
      elem.type === 'Comfy::Cms::Page::Category' && elem.fullPath !== '/landing',
  ) as CmsCategoryPage[];
  return (
    <div className="mx-auto bg-shades-0 shadow-xl">
      {!url.includes(checkoutPath) &&
        !url.includes(servicePath.cloudEnterpriseServices) &&
        !isProductPage(url, appContext.store.cmsPages) && (
          <NavContainer notSticky={url.includes('/products/')} />
        )}
      <div className="mx-auto min-h-[100vh] max-w-screen-4xl">
        <Switch>
          <Route exact path={ourStoryPath}>
            <OurStoryPage />
          </Route>
          <Route exact path={orderDetailsPath}>
            <OrderDetailsPage />
          </Route>
          <Route exact path={helpCenterPath}>
            <HelpCenterPage />
          </Route>
          <Route exact path={`${helpCenterPath}/:selectedSectionSlug`}>
            <HelpCenterPage />
          </Route>
          <Route exact path={termsAndConditionsPath}>
            <InformationPage
              // @ts-ignore
              slug="terms-and-conditions"
              defaultTitle="Terms and Conditions"
              metaTitle="Terms and Conditions | Printivity"
              description="Welcome to Printivity's terms and conditions."
              canonical={`https://www.printivity.com${termsAndConditionsPath}`}
            />
          </Route>
          <Route
            exact
            path={convid19faqsPath}
            render={() => (
              // @ts-ignore
              <InformationPage
                // @ts-ignore
                slug="covid-19-faqs"
                defaultTitle="COVID-19-FAQS"
                metaTitle="COVID-19 FAQS | Printivity"
                description="Here at Printivity, the health and safety of our customers and employees is our top priority. We’d like to share some updates Printivity has undertaken in response to COVID-19, as well as answer some of the questions you may be asking. "
                canonical={`https://www.printivity.com${convid19faqsPath}`}
              />
            )}
          />
          <Route exact path={careersPath}>
            <CareersPage />
          </Route>
          <Route exact path={paperShortagePath}>
            <PaperShortagePage />
          </Route>
          <Route exact path={privacyPath}>
            <InformationPage
              // @ts-ignore
              defaultTitle="Privacy Policy"
              slug="privacy-policy"
              metaTitle="Privacy Policy | Printivity"
              description="Printivity is committed to protecting your privacy and your personal data. | Printivity"
              canonical={`https://www.printivity.com${privacyPath}`}
              robots={['index', 'follow']}
            />
          </Route>
          <Route exact path={hoursAndLocationPath}>
            <HoursAndLocation />
          </Route>
          <Route exact path={contactUsPath}>
            <ContactUsPage />
          </Route>
          <Route exact path={`${contactUsPath}/success`}>
            <Success />
          </Route>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Redirect from="//" exact to="/" />
          <Route exact path={accountPath.account}>
            {userContext.currentUser ? <OrderHistoryPage /> : <Redirect push to={signInPath} />}
          </Route>
          <Route exact path="/account/orders/:orderNumber/tracking">
            {userContext.currentUser ? <TrackingPage /> : <Redirect push to={signInPath} />}
          </Route>
          <Route exact path="/orders/:orderNumber/proof_approvals/:proofApprovalId/edit">
            <ProofApprovalsPage />
          </Route>
          <Route exact path="/account/settings">
            {userContext.currentUser ? (
              <SettingsPage
                // @ts-ignore
                currentUser={userContext.currentUser}
                logIn={userContext.logIn}
              />
            ) : (
              <Redirect push to={signInPath} />
            )}
          </Route>
          <Route exact path="/account/addresses">
            {userContext.currentUser ? <AddressesPage /> : <Redirect push to={signInPath} />}
          </Route>
          <Route exact path="/account/credit_cards">
            {userContext.currentUser ? <CreditCardsPage /> : <Redirect push to={signInPath} />}
          </Route>
          <Route exact path="/account/tax_exemptions">
            {userContext.currentUser ? <TaxExemptionsPage /> : <Redirect push to={signInPath} />}
          </Route>
          <Route exact path="/orders/:orderNumber/payments/new">
            <PaymentPortalPage
              // @ts-ignore
              paymentMethod={appContext.store.paymentMethods[0]}
            />
          </Route>
          <Route exact path="/orders/:orderNumber/payments/success">
            <PaymentSuccessPage />
          </Route>
          <Route exact path="/upload">
            <UploadPage
              // @ts-ignore
              user={userContext.currentUser}
            />
          </Route>
          <Route exact path="/rush-critical">
            <RushCritical />
          </Route>
          <Route exact path="/franchise-solutions">
            <FranchiseSolutions />
          </Route>
          <Route exact path="/box-design">
            <BoxDesign />
          </Route>
          <Route exact path={servicePath.designServices}>
            <DesignServices />
          </Route>
          <Route exact path={servicePath.cloudEnterpriseServices}>
            <CloudEnterpriseServices />
          </Route>
          <Route exact path={servicePath.cloudEnterpriseServicesContactSales}>
            <ContactSales />
          </Route>
          <Route exact path="/site-map">
            <SiteMap />
          </Route>
          <Route exact path="/landing/insights">
            <Insights />
          </Route>
          <Route exact path={`:fullPath(${cmsProductPages.map(page => page.fullPath).join('|')})`}>
            <ProductDetailsPage />
          </Route>
          <Route exact path="/products">
            <ProductListingPage />
          </Route>
          <Route exact path={checkoutPath}>
            <CheckoutPage />
          </Route>
          <Route exact path="/cart">
            <CartPage />
          </Route>
          <Route exact path={signInPath}>
            {!userContext.currentUser ? <SignInPage leftAlign /> : <Redirect to={signInRedirectPath} />}
          </Route>
          <Route exact path="/login">
            {!userContext.currentUser ? <SignInPage leftAlign /> : <Redirect to={signInRedirectPath} />}
          </Route>
          <Route exact path={resetPasswordPath}>
            {!userContext.currentUser ? <ResetPasswordPage /> : <Redirect to={signInRedirectPath} />}
          </Route>
          <Route exact path={signUpPath}>
            {!userContext.currentUser ? <SignUpPage /> : <Redirect to={signInRedirectPath} />}
          </Route>
          <Route exact path={resendConfirmationPath}>
            {!userContext.currentUser ? <ResendConfirmationPage /> : <Redirect to={signInRedirectPath} />}
          </Route>
          <Route path={editPasswordPath}>
            {!userContext.currentUser ? <EditPassword /> : <Redirect to={signInRedirectPath} />}
          </Route>
          <Route exact path={`:fullPath(${cmsCategoryPages.map(page => page.fullPath).join('|')})`}>
            <CategoryPage />
          </Route>
          <Route>
            <FourOhFour />
          </Route>
        </Switch>
      </div>
    </div>
  );
};

export default Routes;
